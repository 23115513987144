<template>
  <div class="pd-20">
    <a-page-header class="pd-0" sub-title="">
      <div slot="title">
        <h1><a-icon class="gradient" type="line-chart" /> VENDAS</h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block; margin-right: -5px"
        >
          <img
            class="c-pointer"
            src="@/assets/images/dashboard/excel.png"
            alt="img"
          />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-tabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <a-tab-pane key="Revisão" tab="REVISÃO" force-render> </a-tab-pane>
      <a-tab-pane key="Finalizado" tab="FINALIZADO" force-render> </a-tab-pane>
      <a-tab-pane key="Cancelado" tab="CANCELADOS" force-render> </a-tab-pane>
    </a-tabs>

    <a-row class="haya-panels" v-if="sale.list.length > 0" :gutter="20">
      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ sale.meta.total }}</div>
          <div class="txt">Vendas</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total cgray">R$ {{ sale.meta.total_value }}</div>
          <div class="txt">Recebido</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total red">R$ {{ sale.meta.total_to_pay }}</div>
          <div class="txt">A pagar</div>
          <div class="footer">
            Tx. Embarque: R$ {{ sale.meta.total_embark_taxes }}
          </div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total corange">R$ {{ sale.meta.total_taxes }}</div>
          <div class="txt">Taxas</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total cgreen">R$ {{ sale.meta.total_profit }}</div>
          <div class="txt">Lucratividade</div>
          <div class="footer"></div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template #header> <a-icon type="filter" /> FILTRAR </template>
        <a-row :gutter="20">
          <a-col :span="3">
            <div class="travel-input">
              <label for class="filled">ID Venda</label>
              <a-input
                placeholder="ID + Enter"
                v-model="sale.filters.id"
                @pressEnter="getSales()"
                @blur="getSales()"
              />
            </div>
          </a-col>

          <a-col :span="3">
            <div class="travel-input">
              <label for class="filled">ID Contrato</label>
              <a-input
                placeholder="ID + Enter"
                v-model="sale.filters.contractId"
                @pressEnter="getSales()"
                @blur="getSales()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Contratante </label>
              <a-auto-complete
                :data-source="
                  customers.list.map(
                    ({
                      first_name,
                      last_name,
                      id,
                      person_type,
                      trading_name,
                    }) => ({
                      value: id,
                      text:
                        person_type === 'Pessoa Física'
                          ? `${id} - ${first_name} ${last_name}`
                          : `${id} - ${trading_name}`,
                    })
                  )
                "
                v-model="customers.filters.customerName"
                style="width: 100%; height: 32px"
                placeholder="Buscar contratantes..."
                @change="onChangeSearchCustomers"
                @select="customerSelected"
              />
            </div>
          </a-col>
          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Período</label>

              <a-range-picker
                v-model="sale.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
              />
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="12">
            <div class="travel-input">
              <label for class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="sale.filters.users.selected"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of sale.filters.users.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
        <a-divider class="mb-15" />
        <a-button
          class="mb-0"
          type="primary"
          :loading="sale.loading"
          @click="getSales()"
          ><a-icon type="search" /> Filtrar vendas</a-button
        >
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="salesTableCollumns"
      :data-source="sale.list"
      :loading="sale.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="saleTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>

      <div slot="contract" slot-scope="record">
        <a @click="$router.push(`/contracts/edit/${record.contract_id}`)">
          {{ record.contract_id }}
        </a>
      </div>

      <div slot="company" slot-scope="record">
        <div class="dotted-phrase">{{ record.company.trading_name }}</div>
      </div>

      <div slot="company_branch" slot-scope="record">
        <div class="dotted-phrase">{{ record.company_branch.name }}</div>
      </div>

      <div slot="value" slot-scope="record">R$ {{ record }}</div>
      <div slot="value_to_pay" slot-scope="record">R$ {{ record }}</div>
      <div slot="value_to_receive" slot-scope="record">
        <div v-if="record != '0,00'" class="cgreen">R$ {{ record }}</div>
      </div>
      <div slot="profit_value" slot-scope="record">R$ {{ record }}</div>
      <div slot="taxes_value" slot-scope="record">R$ {{ record }}</div>
      <div slot="embark_taxes_value" slot-scope="record">
        <div v-if="record != '0,00'" class="red">R$ {{ record }}</div>
      </div>

      <div slot="customer" slot-scope="record">
        <div
          v-if="record.customer.person_type == 'Pessoa Física'"
          class="dotted-phrase upper"
        >
          {{ record.customer.id }} -
          <span v-if="record.customer.first_name">
            {{ record.customer.first_name }}
            {{ record.customer.last_name }}
          </span>

          <span v-if="record.customer.trading_name">
            {{ record.customer.trading_name }}
          </span>
        </div>
        <div v-else>
          {{ record.customer.id }} - {{ record.customer.trading_name }}
        </div>
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <div id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar v-else style="color: #f56a00; background-color: #fde3cf">
              {{ record.user.first_name }}
            </a-avatar>
          </div>
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <SalesTableActions
          v-if="!sale.loading"
          :sale="record"
          :activeTab="activeTab"
          @editSale="editSale"
          @viewSale="viewSale"
          @printSale="onClickOpenPrintSaleModal"
        />
      </div>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="sale.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="sale.pagination.perPage"
        :total="sale.pagination.total"
        @change="changeSalesPage"
        @showSizeChange="changeSalesPageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>

    <a-modal
      class="sale-outer-modal"
      :title="false"
      :footer="false"
      :width="$root.windowWidth > 960 ? '1160px' : '100%'"
      v-model="openViewSale"
      @cancel="onCloseSaleModal"
    >
      <SaleModal
        v-if="openViewSale"
        :id="saleId"
        @closeModal="onCloseSaleModal"
      />
    </a-modal>

    <a-modal
      :footer="false"
      :width="$root.windowWidth > 960 ? '600px' : '100%'"
      v-model="openEditSale"
      @cancel="onCloseEditSaleModal"
    >
      <div slot="title">VENDA {{ saleId }} - EDITAR</div>
      <EditSaleModal
        v-if="openEditSale"
        :id="saleId"
        :saleCustomer="saleCustomer"
      />
    </a-modal>

    <a-modal
      :footer="false"
      :width="'883px'"
      v-model="openPrintSaleModal"
      @cancel="openPrintSaleModal = false"
    >
      <div slot="title">
        <aRow type="flex" justify="space-between">
          <aCol> VENDA #{{ saleId }} </aCol>

          <aCol>
            <a-button
              class="print-button"
              type="primary"
              shape="circle"
              icon="printer"
              @click="onClickButtonPrintSale"
            />
          </aCol>
        </aRow>
      </div>
      <PrintSaleSection
        v-if="openPrintSaleModal"
        ref="PrintSaleSection"
        :sale="sale.details"
      />
    </a-modal>
  </div>
</template>

<script>
// libs
import _ from "lodash";
import {
  format,
  parse,
  parseISO,
  isAfter,
  isBefore,
  addDays,
  isValid,
} from "date-fns";

import saleMixins from "@/mixins/sales/saleMixins.js";
import customerMixins from "@/components/customers/mixins/customerMixins";
import SaleModal from "@/components/sales/modal/SaleModal.vue";
import EditSaleModal from "@/components/sales/modal/EditSaleModal.vue";
import SalesTableActions from "@/components/sales/sections/SalesTableActions.vue";
import PrintSaleSection from "@/components/sales/sections/PrintSaleSection.vue";

export default {
  components: { SaleModal, EditSaleModal, SalesTableActions, PrintSaleSection },
  mixins: [saleMixins, customerMixins],
  data() {
    return {
      activeTab: "Revisão",
      activeSaleTab: "Revisão",
      saleId: "",
      contractId: "",
      saleCustomer: "",
      openViewSale: false,
      openEditSale: false,
      openPrintSaleModal: false,
      form: this.$form.createForm(this),
      usersList: [],
      pageSizeOptions: ["10", "20", "25", "50", "100", "200", "500"],
      excelFile: {
        header: "Vendas",
        fileName: "Vendas.xls",
        collumns: {
          ID: "id",
          Contrato: {
            field: "contract",
            callback: (contract) => {
              return `${contract.id}`;
            },
          },
          Produtos: {
            field: "contract",
            callback: (contract) => {
              return `${this.formatProductsTxt(contract.meta)}`;
            },
          },
          Filial: {
            field: "company_branch",
            callback: (company_branch) => {
              return `${company_branch.name}`;
            },
          },
          Cliente: {
            field: "customer",
            callback: (customer) => {
              return `${customer.id} - ${customer.first_name} ${customer.last_name}`;
            },
          },
          Venda: {
            field: "value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          "A pagar": {
            field: "value_to_pay",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },

          Taxas: {
            field: "taxes_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Lucratividade: {
            field: "profit_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Pagamentos: {
            field: "contract",
            callback: (contract) => {
              return `${this.formatPaymentsTxt(contract.meta)}`;
            },
          },
          "Data da venda": "sale_date",
          Status: "status",
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  beforeMount() {
    document.title = "Vendas - Haya";

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.sale.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("period"))
      this.sale.filters.period.selected = urlParams.get("period").split("|");

    if (urlParams.get("id")) this.sale.filters.id = urlParams.get("id");

    if (urlParams.get("status")) {
      this.sale.filters.status.selected = urlParams.get("status").split(",");

      this.activeTab = urlParams.get("status");
    } else {
      this.sale.filters.status.selected = ["Revisão"];
    }

    this.sale.pagination.perPage = 10;
    this.getSales();
    this.getUsers();

    if (urlParams.get("open-modal") && urlParams.get("id"))
      this.viewSale(urlParams.get("id"));
  },
  methods: {
    onChangeSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.getCustomers();
      } else {
        this.customers.filters.searchTerm = "";
        this.sale.filters.customer = "";
      }
    }, 600),
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.sale.filters.status.selected = tab;
      this.sale.pagination.page = 1;
      this.getSales();
    },
    clearCustomerField() {
      this.sale.filters.customer = "";
      this.getSales();
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    onCloseSaleModal() {
      this.openViewSale = false;
      this.getSales(this.sale.pagination.page);

      document.title = "Vendas - HAYA";
    },
    onCloseEditSaleModal() {
      this.openEditSale = false;
      this.saleCustomer = "";
      this.getSales(this.sale.pagination.page);

      document.title = "Vendas - HAYA";
    },
    checkinIsComing(sale, contract) {
      let flag = false;
      if (
        contract.status === "concluded" &&
        this.isDateWithinNextDays(
          contract.first_checkin,
          sale.company_branch.days_to_checkin_alert
        )
      )
        flag = true;
      return flag;
    },
    isDateWithinNextDays(dateStr, days) {
      // Check if dateStr is provided
      if (!dateStr) return false;

      // Parse the date string
      const targetDate = parseISO(dateStr);

      // Check if the parsed date is valid
      if (!isValid(targetDate)) return false;

      // Get today's date
      const today = new Date();

      // Get the date 90 days from today
      const dateIn90Days = addDays(today, days);

      // Check if the target date is between today and the date 90 days from today
      return isAfter(targetDate, today) && isBefore(targetDate, dateIn90Days);
    },
    viewSale(id) {
      this.openViewSale = true;
      this.saleId = id;
    },
    onClickOpenPrintSaleModal(sale) {
      this.openPrintSaleModal = true;
      this.sale.details = sale;
      this.saleId = sale.id;
    },
    onClickButtonPrintSale() {
      this.$refs.PrintSaleSection.$emit("onClickPrintSale", true);
    },
    editSale(sale) {
      this.openEditSale = true;
      this.saleId = sale.id;
      this.saleCustomer = sale.customer;
    },
    gettheAvatar(id) {
      let theuser = this.usersList.filter((user) => {
        return user.id == id;
      });
      return theuser.length > 0 ? theuser[0].avatar : "";
    },
    customerSelected(customer) {
      this.customers.filters.searchTerm = customer;
      this.sale.filters.customer = customer;
      this.getSales();
    },
    getUsers() {
      this.$http
        .get("/user/list?page=1&per_page=100&user_id=1")
        .then(({ data }) => {
          this.usersList = data.data;
          this.sale.filters.users.list = data.data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    changeOrdersTabs(tab) {
      this.activeSaleTab = tab;
      this.sale.filters.status.selected = tab;
      this.getSales();
    },
    milesPaymentCheck(meta) {
      let flag = false;
      if (meta.flight_sections_rows != undefined) {
        JSON.parse(meta.flight_sections_rows).forEach((flight) => {
          if (meta[`flight_${flight.id}_type`] == "Milhas") {
            flag = true;
          }
        });
      }
      return flag;
    },
    formatCategory(categories) {
      let txt = "";

      JSON.parse(categories).forEach((category) => {
        txt += category + ", ";
      });

      txt = txt.slice(0, -2);

      return txt;
    },
    listSales() {
      this.getSales();
      //this.openCreateSale = false;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    formatPaymentsTxt(meta) {
      let txt = "";
      if (meta.payment_methods != undefined) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") txt += "Cartão de Crédito,";
          if (payment == "billing-ticket") txt += "Boleto,";
          if (payment == "payment-card") txt += "Carta de Pagamento,";
          if (payment == "custom-payment")
            txt += `${meta.payment_methods_custom_payment_name}*,`;
          if (payment == "bank-transfer") txt += "Transferência Bancária,";
          if (payment == "payment-link") txt += "Link de Pagamento,";
        });
      }
      return txt;
    },
    formatProductsTxt(meta) {
      let txt = "";

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel")
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                txt += meta[`hotel_${row.id}_name`]
                  ? meta[`hotel_${row.id}_name`] + "<br>"
                  : `HOTEL ${row.id} <br>`;
              }
            });

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") type = "";

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                txt += meta[`service_${row.id}_name`];
              }
            });
          }
        });
      }

      return txt.toUpperCase();
    },
    formatProductsList(contractedServices) {
      let allProducts = [];

      if (contractedServices) {
        JSON.parse(contractedServices).forEach((product) => {
          if (product == "hotel") {
            allProducts.push({
              color: "green",
              name: "HOTEL",
            });
          }

          if (product == "flight") {
            allProducts.push({
              color: "blue",
              name: "AÉREO",
            });
          }

          if (product == "service") {
            allProducts.push({
              color: "orange",
              name: "SERVIÇO",
            });
          }
        });
      }

      return allProducts;
    },
    formatPayments(meta) {
      let allPayments = [];

      if (meta.payment_methods != undefined) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") {
            allPayments.push({
              name: "Cartão de Crédito",
              color: "orange",
              type: payment,
            });
          }

          if (payment == "billing-ticket") {
            allPayments.push({
              name: "Boleto",
              color: "purple",
              type: payment,
            });
          }

          if (payment == "payment-card") {
            allPayments.push({
              name: "Carta de Pagamento",
              color: "blue",
              type: payment,
            });
          }

          if (payment == "custom-payment") {
            allPayments.push({
              name: `${meta.payment_methods_custom_payment_name}* `,
              color: "blue",
              type: payment,
            });
          }

          if (payment == "bank-transfer") {
            allPayments.push({
              name: "Transferência Bancária",
              color: "blue",
              type: payment,
            });
          }

          if (payment == "payment-link") {
            allPayments.push({
              name: "Link de Pagamento",
              color: "orange",
              type: payment,
            });
          }
        });
      }

      return allPayments;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category = JSON.stringify(values.category);
        values.product_supplier_ids = JSON.stringify(
          values.product_supplier_ids
        );
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (!err) {
          this.loadingCreateSale = true;

          this.$http
            .post("/sale/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateSale = false;
              this.listSales();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateSale = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass">
.payment-types
  span
    img
      margin: 0 5px 0 0
  span:last-child
    img
      margin: 0
.sale-outer-modal
  .ant-modal-close
    background: #cbcbcb!important
    border-radius: 0 0 0 5px
    .ant-modal-close-x
      width: 26px
      height: 18px
      font-size: 12px
      line-height: 19px
  .ant-modal-body
    padding: 0
</style>

<style lang="sass" scoped>
.print-button
  margin-right: 30px
#avatar
  opacity: 0.7
  transition: .3s
#avatar:hover
  opacity: 1
</style>
